export const skills1 = [
  {
    name: "MongoDB",
    iconClass: "devicon-mongodb-plain",
  },
  {
    name: "Express",
    iconClass: "devicon-express-original",
  },
  {
    name: "ReactJS",
    iconClass: "devicon-react-plain",
  },
  {
    name: "NodeJS",
    iconClass: "devicon-nodejs-plain",
  }]
  export const skills2 = [
  {
    name: "SASS",
    iconClass: "devicon-sass-original",
  },
  {
    name: "Bootstrap",
    iconClass: "devicon-bootstrap-plain",
  },

  {
    name: "threeJS",
    iconClass: "devicon-threejs-original",
  }]
  export const skills3 =[
  {
    name: "C#",
    iconClass: " devicon-csharp-plain",
  },

  {
    name: "PHASER",
    iconClass: 'C:\Users\slimm\Desktop\Projects\My_website\My_react_website\public\images\Phaser.png'
  },

  {
    name: "MaterialUI",
    iconClass: "devicon-materialui-plain",
  }
];
